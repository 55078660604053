<template>
  <div class="wrap">
    <van-empty
      class="custom-image"
      image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
      description="带入正确的 userId 后再试"
    />
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
.wrap {
  height: 100vh;
  display: flex;
  justify-content: center;
}
.bottom-button {
  width: 160px;
  height: 40px;
}
</style>
